@import "mixins";
@import "variables";
.topcard_bg {
    display: flex;
    flex-direction: column;
    // flex: 1;
    position: relative;
    height: 1180px;
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    margin-top: 60px;

    @media (max-width: $mobile-size) {
        height: 605px;
        margin: 0;
    }

    .topsection {
        width: 100%;
        height: 1180px;
        background-color: #000000;

        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: $mobile-size) {
            height: 605px;
        }

        .bgimg_wrap_mobile {
            height: 605px;
            width: 100vw;
            position: relative;
            overflow: hidden;
            display: none;

            @media (max-width: $mobile-size) {
                display: inline;
            }

            .title_container_mobile {
                display: flex;
                flex-direction: column;
                width: 240px;
                height: 72px;
                position: absolute;
                left: 50%;
                z-index: 2;
                transform: translateX(-50%);

                .title_mobile {
                    // @include fontBold(40px, #fff);
                    // width: 100%;
                    // height: 48px;
                    // line-height: 48px;
                    width: 700px;
                    height: 154px;
                    background-repeat: no-repeat;
                    background-image: url(../../../Assets/Images/bgimg_mobile_title.png);

                    // animation: fadeInUp 5s;
                    top: 0;
                }

                .sub_mobile {
                    @include fontLight(20px, #fff);
                    width: 100%;
                    height: 24px;
                    line-height: 24px;
                    animation: fadeInUp 5s;
                    top: 0;
                }
            }

            .bgimg_mobile {
                position: absolute;
                height: 500px;
                width: 700px;
                background-size: 700px 100%;
                top: calc(50% + 40px);
                left: 50%;
                transform: translateX(-50%) translateY(-50%);

                background-repeat: no-repeat;
                background-image: url(../../../Assets/Images/bgimg-mobile.png);
            }

            .bgimg_mobile_title {
                position: absolute;
                width: 700px;
                height: 154px;
                background-size: 700px 100%;
                top: calc(50% - 180px);
                left: 50%;
                transform: translateX(-50%) translateY(-50%);

                background-repeat: no-repeat;
                background-image: url(../../../Assets/Images/bgimg_mobile_title.png);
            }
        }

        .bgimg_wrap {
            height: 1180px;
            width: 100vw;
            position: relative;
            background-color: #000;

            @media (max-width: $mobile-size) {
                display: none;
            }

            .bgimg {
                position: absolute;
                height: 1180px;
                width: 1920px;
                background-size: 1920px 100%;
                top: 0;
                left: 50%;
                transform: translate(-50%, 0);

                background-repeat: no-repeat;
                background-image: url(../../../Assets/Images/bgimg.png);

                .title_container {
                    position: absolute;
                    top: 468px;
                    left: 985px;
                    width: 360px;
                    height: 100px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    .title {
                        @include fontBold(60px, #fff);
                        line-height: 72px;
                        animation: fadeInUp 5s;
                    }
                    .sub {
                        @include fontLight(24px, #fff);
                        line-height: 20px;
                        animation: fadeInUp 5s;
                    }
                }
            }
        }
    }

    @keyframes fadeInUp {
        0% {
            opacity: 0;
            transform: translate3d(0, 20%, 0);
        }
        to {
            opacity: 1;
            transform: translateZ(0);
        }
    }
}
