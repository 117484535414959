@import "mixins";
@import "variables";

.introductioncard1_bg {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #000;

    height: 100vh;

    @media (max-width: $mobile-size) {
        height: 844px;
    }

    .prevbutton {
        width: 160px;
        height: 40px;
        border-radius: 4px;
        border: solid 1px rgba(255, 255, 255, 0.3);
        background-color: rgba(216, 216, 216, 0.1);
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        color: #fff;
        line-height: 40px;
        position: absolute;
        top: 669px;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
        z-index: 7;
        margin-left: -90px;
        opacity: 0;
        transition-duration: 0.5s;
        -ms-user-select: none;
        -moz-user-select: -moz-none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        user-select: none;

        @media (max-width: $mobile-size) {
            width: 150px;
            margin: 0 10px 0 0;
            width: calc(50% - 20px);
        }

        &:hover {
            cursor: pointer;
            background: white;
            color: black;
        }

        &:active {
            background-color: #808080;
            color: white;
        }

        @media (max-width: $mobile-size) {
            width: calc(100% - 50px);
            top: 460px;
        }
    }

    .topview_nextbutton {
        position: absolute;
        top: 669px;
        left: 50%;
        transform: translateX(-50%);
        width: 160px;
        height: 40px;
        line-height: 40px;
        border-radius: 4px;
        border: solid 1px #fff;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        color: #1bffbc;
        border-radius: 4px;
        border: solid 1px #1bffbc;
        background-color: rgba(216, 216, 216, 0.1);
        cursor: pointer;
        z-index: 8;
        transition-duration: 0.5s;
        -ms-user-select: none;
        -moz-user-select: -moz-none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        user-select: none;

        @media (max-width: $mobile-size) {
            width: calc(100% - 50px);
            top: 460px;
        }

        &:hover {
            cursor: pointer;
            background-color: #1bffbc;
            color: #000;
        }

        &:active {
            background-color: #0d9599;
            color: white;
        }
    }

    .middleview {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 800px;
        width: 100%;

        @media (max-width: $mobile-size) {
            height: 510px;
        }

        .imgs {
            position: absolute;
            left: 50%;
            top: 223px;
            transform: translateX(-50%);
            width: 1100px;
            height: 542px;
            background: transparent;
            z-index: 6;
            // transition-duration: 2s;

            .img1 {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 230px;
                height: 230px;
                background-image: url("../../../Assets/Images/card-img-1.png");
                background-size: cover;
                transition-delay: 1.5s;
                transition-duration: 0.5s;
                animation: moveUpDown 4s infinite;
            }

            .img4 {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 230px;
                height: 230px;
                background-image: url("../../../Assets/Images/card-img-4.png");
                background-size: cover;
                transition-delay: 1s;
                transition-duration: 0.5s;
                animation: moveDownUp 4s infinite;
            }

            @media (max-width: $mobile-size) {
                display: none;
            }
        }

        .imgs2 {
            position: absolute;
            left: 50%;
            top: 223px;
            transform: translateX(-50%);
            width: 1100px;
            height: 542px;
            background: transparent;
            z-index: 2;

            .img2 {
                position: absolute;
                top: 0;
                right: 20px;
                width: 210px;
                height: 210px;
                background-image: url("../../../Assets/Images/card-img-2.png");
                background-size: cover;
                transition-duration: 1s;
            }

            .img3 {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 254px;
                height: 250px;
                background-image: url("../../../Assets/Images/card-img-3.png");
                background-size: cover;
                transition-duration: 2s;
            }

            @media (max-width: $mobile-size) {
                display: none;
            }
        }

        .bottomview {
            position: absolute;
            top: 0;
            left: 0;
            height: 324px;
            box-shadow: 0 -1px 35px 0 #2d798b;
            background-color: #fff;
            width: 100vw;
            z-index: 1;

            @media (max-width: $mobile-size) {
                height: 180px;
                box-shadow: none;
            }

            .bottomview_wrap {
                height: 440px;
                width: 100vw;
                position: relative;
                overflow: hidden;
                background-color: #000;
                border-top: 1px solid rgba(#1bffbc, 0.5);

                @media (max-width: $mobile-size) {
                    height: 180px;
                }

                .bottomview_bg {
                    background-image: url("../../../Assets/Images/tpt-img-card-bg.png");
                    // height: 440px;
                    height: 324px;
                    width: 1266px;
                    background-repeat: no-repeat;
                    background-size: 1266px 100%;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);

                    @media (max-width: $mobile-size) {
                        height: 180px;
                        width: 700px;
                        background-size: 700px 100%;
                    }

                    .cards_phase1 {
                        display: none;

                        @media (max-width: $mobile-size) {
                            display: block;
                            width: 230px;
                            height: 200px;
                            background-image: url("../../../Assets/Images/m_card_01.png");
                            background-size: cover;
                            position: absolute;
                            top: 5px;
                            left: 50%;
                            transform: translateX(-50%);
                            transition-duration: 2s;
                        }
                    }

                    .cards_phase2 {
                        display: none;

                        @media (max-width: $mobile-size) {
                            display: block;
                            width: 230px;
                            height: 200px;
                            background-image: url("../../../Assets/Images/m_card_02.png");
                            background-size: cover;
                            position: absolute;
                            top: 5px;
                            left: 50%;
                            transform: translateX(-50%);
                            opacity: 0;
                            transition-duration: 2s;
                        }
                    }

                    .cards_phase3 {
                        display: none;

                        @media (max-width: $mobile-size) {
                            display: block;
                            width: 230px;
                            height: 200px;
                            background-image: url("../../../Assets/Images/m_card_03.png");
                            background-size: cover;
                            position: absolute;
                            top: 5px;
                            left: 50%;
                            transform: translateX(-50%);
                            opacity: 0;
                            transition-duration: 2s;
                        }
                    }

                    .cards_phase4 {
                        display: none;

                        @media (max-width: $mobile-size) {
                            display: block;
                            width: 230px;
                            height: 200px;
                            background-image: url("../../../Assets/Images/m_card_04.png");
                            background-size: cover;
                            position: absolute;
                            top: 5px;
                            left: 50%;
                            transform: translateX(-50%);
                            opacity: 0;
                            transition-duration: 2s;
                        }
                    }
                    .cards_phase5 {
                        display: none;

                        @media (max-width: $mobile-size) {
                            display: block;
                            width: 230px;
                            height: 200px;
                            background-image: url("../../../Assets/Images/m_card_05.png");
                            background-size: cover;
                            position: absolute;
                            top: 5px;
                            left: 50%;
                            transform: translateX(-50%);
                            opacity: 0;
                            transition-duration: 2s;
                        }
                    }

                    .cards_phase6 {
                        display: none;

                        @media (max-width: $mobile-size) {
                            display: block;
                            width: 230px;
                            height: 200px;
                            background-image: url("../../../Assets/Images/m_card_06.png");
                            background-size: cover;
                            position: absolute;
                            top: 5px;
                            left: 50%;
                            transform: translateX(-50%);
                            opacity: 0;
                            transition-duration: 2s;
                        }
                    }

                    .nowstate_container {
                        width: 400px;
                        height: 30px;
                        position: absolute;
                        top: 25px;
                        left: 50%;
                        transform: translateX(-50%);
                        -ms-user-select: none;
                        -moz-user-select: -moz-none;
                        -khtml-user-select: none;
                        -webkit-user-select: none;
                        user-select: none;

                        @media (max-width: $mobile-size) {
                            top: 15px;
                        }

                        .state1 {
                            position: relative;
                            opacity: 0;
                            transition-duration: 1s;

                            .state_text {
                                position: absolute;
                                top: 0;
                                left: 50%;
                                transform: translateX(-50%);
                                @include fontBold(20px, #1bffbc);

                                @media (max-width: $mobile-size) {
                                    @include font(16px, #1bffbc);
                                }
                            }
                        }

                        .state2 {
                            position: relative;
                            opacity: 0;
                            transition-duration: 1s;

                            .state_text {
                                position: absolute;
                                top: 0;
                                left: 50%;
                                transform: translateX(-50%);
                                @include fontBold(20px, #1bffbc);

                                @media (max-width: $mobile-size) {
                                    @include font(16px, #1bffbc);
                                }
                            }
                        }

                        .state3 {
                            position: relative;
                            opacity: 0;
                            transition-duration: 1s;

                            .state_text {
                                position: absolute;
                                top: 0;
                                left: 50%;
                                transform: translateX(-50%);
                                @include fontBold(20px, #1bffbc);

                                @media (max-width: $mobile-size) {
                                    @include font(16px, #1bffbc);
                                }
                            }
                        }

                        .state4 {
                            position: relative;
                            opacity: 0;
                            transition-duration: 1s;

                            .state_text {
                                position: absolute;
                                top: 0;
                                left: 50%;
                                transform: translateX(-50%);
                                @include fontBold(20px, #1bffbc);

                                @media (max-width: $mobile-size) {
                                    @include font(16px, #1bffbc);
                                }
                            }
                        }
                    }

                    .opencard_wrapper_dimmed {
                        width: 450px;
                        height: 140px;
                        margin: 70px auto 0 auto;
                        position: absolute;
                        top: 0;
                        left: 410px;
                        transition-duration: 2s;
                        opacity: 0.8;
                        display: none;

                        @media (max-width: $mobile-size) {
                            display: block;
                            width: 220px;
                            height: 80px;
                            margin: 40px auto 0 auto;
                            left: 240px;
                        }

                        .opencard_table_dimmed {
                            width: 550px;
                            height: 140px;
                            transform-style: preserve-3d;
                            flex-wrap: wrap;
                            display: flex;
                            flex-direction: row;
                            transform: perspective(350px) rotateX(20deg);
                            perspective: 1200px;
                            margin: 0 0 0 -60px;
                            background: green;

                            @media (max-width: $mobile-size) {
                                width: 220px;
                                height: 80px;
                                margin: 0 auto;
                                transform: perspective(200px) rotateX(20deg);
                            }

                            .card2_dimmed {
                                position: absolute;
                                height: 98px;
                                width: 72px;
                                top: 20px;
                                left: 166px;
                                background-color: #000;
                                border-radius: 5px;

                                @media (max-width: $mobile-size) {
                                    width: 34px;
                                    height: 46px;
                                    top: 15px;
                                    left: 12px;
                                    border-radius: 2px;
                                }
                            }

                            .card2_dimmed2 {
                                position: absolute;
                                height: 98px;
                                width: 72px;
                                top: 20px;
                                left: 320px;
                                background-color: #000;
                                border-radius: 5px;

                                @media (max-width: $mobile-size) {
                                    width: 34px;
                                    height: 46px;
                                    top: 15px;
                                    left: 52px;
                                    border-radius: 2px;
                                }
                            }

                            .card2_dimmed3 {
                                position: absolute;
                                height: 98px;
                                width: 72px;
                                top: 20px;
                                left: 89px;
                                background-color: #000;
                                border-radius: 5px;

                                @media (max-width: $mobile-size) {
                                    width: 34px;
                                    height: 46px;
                                    top: 15px;
                                    left: 133px;
                                    border-radius: 2px;
                                }
                            }
                        }
                    }

                    .opencard_wrapper {
                        width: 450px;
                        height: 140px;
                        margin: 70px auto 0 auto;
                        z-index: 2000;

                        @media (max-width: $mobile-size) {
                            display: none;
                            width: 220px;
                            height: 80px;
                            margin: 40px auto 0 auto;
                        }

                        .opencard_table {
                            width: 550px;
                            height: 140px;
                            transform-style: preserve-3d;
                            flex-wrap: wrap;
                            display: flex;
                            flex-direction: row;
                            transform: perspective(350px) rotateX(20deg);
                            perspective: 1200px;
                            margin: 0 0 0 -60px;

                            @media (max-width: $mobile-size) {
                                width: 220px;
                                height: 80px;
                                margin: 0 auto;
                                transform: perspective(200px) rotateX(20deg);
                            }

                            .card1 {
                                transform-origin: 100% 100%;
                                transform-style: preserve-3d;
                                position: relative;
                                // height: 135px;
                                height: 98px;
                                width: 72px;

                                background-size: cover;
                                background-image: url("../../../Assets/Images/card-off-02.svg");
                                // width: 99px;

                                display: flex;
                                justify-content: center;
                                align-items: center;
                                transform: rotateY(0deg) translateZ(1px);
                                transform: scaleX(1);
                                right: 0;
                                margin: 20px 4px 0 96px;
                                transition-duration: 0.6s;

                                @media (max-width: $mobile-size) {
                                    width: 35px;
                                    height: 48px;
                                    margin: 15px 5px 0 12px;
                                }

                                &:before,
                                &:after {
                                    content: "";
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    bottom: 0;
                                    left: 0;
                                    transform-style: preserve-3d;
                                }
                                &:before {
                                    transform: translateZ(-1px);
                                    background-size: cover;
                                    background-image: url("../../../Assets/Images/card-on-s-7.svg");
                                }
                            }

                            .card2 {
                                transform-origin: 100% 100%;
                                transform-style: preserve-3d;
                                position: relative;
                                // height: 135px;
                                height: 98px;
                                width: 72px;
                                background-size: cover;
                                background-image: url("../../../Assets/Images/card-off-02.svg");
                                // width: 99px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                transform: rotateY(0deg) translateZ(1px);
                                transform: scaleX(1);
                                right: 0px;
                                margin: 20px 4px 0 0;
                                transition-duration: 0.8s;

                                @media (max-width: $mobile-size) {
                                    width: 35px;
                                    height: 48px;
                                    margin: 15px 5px 0 0;
                                }

                                &:before,
                                &:after {
                                    content: "";
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    bottom: 0;
                                    left: 0;
                                    transform-style: preserve-3d;
                                }
                                &:before {
                                    transform: translateZ(-1px);
                                    background-size: cover;
                                    background-image: url("../../../Assets/Images/card-on-h-4.svg");
                                }
                            }

                            .card3 {
                                transform-origin: 100% 100%;
                                transform-style: preserve-3d;
                                position: relative;
                                // height: 135px;
                                height: 98px;
                                width: 72px;
                                background-size: cover;
                                background-image: url("../../../Assets/Images/card-off-02.svg");
                                // width: 99px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                transform: rotateY(0deg) translateZ(1px);
                                transform: scaleX(1);
                                right: 0px;
                                margin: 20px 4px 0 0;
                                transition-duration: 1s;

                                @media (max-width: $mobile-size) {
                                    width: 35px;
                                    height: 48px;
                                    margin: 15px 5px 0 0;
                                }

                                &:before,
                                &:after {
                                    content: "";
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    bottom: 0;
                                    left: 0;
                                    transform-style: preserve-3d;
                                }
                                &:before {
                                    transform: translateZ(-1px);
                                    background-size: cover;
                                    background-image: url("../../../Assets/Images/card-on-ca.svg");
                                }
                            }

                            .card4 {
                                transform-origin: 100% 100%;
                                transform-style: preserve-3d;
                                position: relative;
                                // height: 135px;
                                height: 98px;
                                width: 72px;
                                background-size: cover;
                                background-image: url("../../../Assets/Images/card-off-02.svg");
                                // width: 99px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                transform: rotateY(0deg) translateZ(1px);
                                transform: scaleX(1);
                                right: 0px;
                                margin: 20px 4px 0 0;
                                transition-duration: 0.7s;

                                @media (max-width: $mobile-size) {
                                    width: 35px;
                                    height: 48px;
                                    margin: 15px 5px 0 0;
                                }

                                &:before,
                                &:after {
                                    content: "";
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    bottom: 0;
                                    left: 0;
                                    transform-style: preserve-3d;
                                }
                                &:before {
                                    transform: translateZ(-1px);
                                    background-size: cover;
                                    background-image: url("../../../Assets/Images/card-on-d-7.svg");
                                }
                            }

                            .card5 {
                                transform-origin: 100% 100%;
                                transform-style: preserve-3d;
                                position: relative;
                                // height: 135px;
                                height: 98px;
                                width: 72px;
                                background-size: cover;
                                background-image: url("../../../Assets/Images/card-off-02.svg");
                                // width: 99px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                transform: rotateY(0deg) translateZ(1px);
                                transform: scaleX(1);
                                right: 0px;
                                margin: 20px 0 0 0;
                                transition-duration: 0.6s;

                                @media (max-width: $mobile-size) {
                                    width: 35px;
                                    height: 48px;
                                    margin: 15px 0 0 0;
                                }

                                &:before,
                                &:after {
                                    content: "";
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    bottom: 0;
                                    left: 0;
                                    transform-style: preserve-3d;
                                }
                                &:before {
                                    transform: translateZ(-1px);
                                    background-size: cover;
                                    background-image: url("../../../Assets/Images/card-on-h-a.svg");
                                }
                            }
                        }
                    }

                    .mycard_table {
                        width: 262px;
                        height: 140px;
                        // margin: 0 auto;
                        position: absolute;
                        bottom: -70px;
                        left: 50%;
                        transform: translateX(-50%);
                        transform-style: preserve-3d;
                        flex-wrap: wrap;
                        display: flex;
                        flex-direction: row;
                        // transform: rotateX(20deg);

                        @media (max-width: $mobile-size) {
                            display: none;
                            width: 140px;
                            height: 100px;
                            top: 120px;
                            margin: 5px auto 0 auto;
                        }

                        .cardhalf1 {
                            transform-origin: 100% 100%;
                            transform-style: preserve-3d;
                            position: relative;
                            // height: 225px;
                            height: 178px;
                            width: 131px;
                            background-size: cover;
                            background-image: url("../../../Assets/Images/card-off-01.svg");
                            // width: 165px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            transform: rotateY(0deg) translateZ(1px);
                            transform: scaleX(1);
                            right: 0px;
                            transition-duration: 0.8s;

                            @media (max-width: $mobile-size) {
                                width: 70px;
                                height: 90px;
                                margin: 0 0 2px 0;
                            }

                            &:before,
                            &:after {
                                content: "";
                                display: block;
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                transform-style: preserve-3d;
                            }
                            &:before {
                                transform: translateZ(-1px);
                                background-size: cover;
                                background-image: url("../../../Assets/Images/card-on-sa.svg");
                            }
                        }

                        .cardhalf2 {
                            transform-origin: 100% 100%;
                            transform-style: preserve-3d;
                            position: relative;
                            // height: 225px;
                            height: 178px;
                            width: 131px;
                            background-size: cover;
                            background-image: url("../../../Assets/Images/card-off-01.svg");
                            // width: 165px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            transform: rotateY(0deg) translateZ(1px);
                            transform: scaleX(1);
                            right: 0px;
                            transition-duration: 0.8s;

                            @media (max-width: $mobile-size) {
                                width: 70px;
                                height: 90px;
                                margin: 0;
                            }

                            &:before,
                            &:after {
                                content: "";
                                display: block;
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                transform-style: preserve-3d;
                                border-radius: 6px;
                            }
                            &:before {
                                transform: translateZ(-1px);
                                background-size: cover;
                                background-image: url("../../../Assets/Images/card-on-ha.svg");
                            }
                        }
                    }
                }
            }
        }

        .textbox {
            border-radius: 20px;
            border: solid 1px #1bffbc;
            background-color: #04041e;
            width: 760px;
            height: 280px;
            position: absolute;
            top: 350px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            flex-direction: column;
            z-index: 4;

            @media (max-width: $mobile-size) {
                width: calc(100% - 20px);
                height: 244px;
                left: 50%;
                top: 180px;
                transform: translateX(-50%);
            }

            .topview_title_container {
                width: 100%;
                height: 280px;
                transition-duration: 0.5s;

                .topview_title {
                    position: absolute;
                    @include fontBold(42px, #fff);
                    top: 92px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 100%;
                    white-space: pre-wrap;
                    z-index: 2;
                    transition-duration: 1s;

                    @media (max-width: $mobile-size) {
                        left: 50%;
                        width: 100%;
                        transform: translateX(-50%);
                        @include fontBold(28px, #fff);
                        line-height: 40px;
                    }
                }

                .topview_title_wrap {
                    position: absolute;
                    -webkit-filter: blur(7px);
                    filter: blur(7px);
                    @include fontBold(42px, #07ffee);
                    top: 92px;
                    left: 50%;
                    transform: translateX(-50%);
                    white-space: pre-wrap;
                    z-index: 1;
                    width: 100%;
                    animation: blink-effect 2s infinite;
                    transition-duration: 1s;

                    @media (max-width: $mobile-size) {
                        left: 50%;
                        transform: translateX(-50%);
                        width: 100%;
                        @include fontBold(28px, #1bcaff);
                        line-height: 40px;
                    }
                }
            }

            .topview_container_end {
                width: 100%;
                height: 100px;
                line-height: 60px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                opacity: 0;
                transition-duration: 0.5s;
                z-index: 4;

                @media (max-width: $mobile-size) {
                    width: 100%;
                    height: 85%;
                    top: 50%;
                    transform: translateY(-50%) translateX(-50%);
                }

                .topview_title_end1 {
                    position: absolute;
                    top: 50px;
                    left: 50%;
                    width: calc(100%);
                    transform: translateX(-50%);
                    @include font(24px, #fff);
                    z-index: 2;

                    .topview_title_end1wrap {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;

                        // @media (max-width: $mobile-size) {
                        //     line-height: 10px;
                        //     flex-direction: column;
                        // }

                        .topview_title_end1_first {
                            margin: 0 0 20px 0;
                        }

                        .topview_title_end1_second {
                            margin: 0 0 25px 4px;
                        }
                    }

                    @media (max-width: $mobile-size) {
                        top: 80%;
                        transform: translateX(-50%) translateY(-80%);
                        @include fontBold(20px, #fff);
                        width: calc(100%);
                    }
                }
                .topview_title_end2 {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    width: 100%;
                    transform: translateX(-50%);
                    @include fontBold(42px, #fff);
                    z-index: 2;

                    @media (max-width: $mobile-size) {
                        top: 40%;
                        transform: translateX(-50%) translateY(-40%);
                        @include fontBold(28px, #fff);
                        width: 100%;
                    }
                }

                .topview_title_end1_wrap {
                    position: absolute;
                    top: 50px;
                    left: 50%;
                    width: 100%;
                    transform: translateX(-50%);
                    @include fontBold(20px, #1bcaff);
                    -webkit-filter: blur(10px);
                    filter: blur(10px);
                    z-index: 1;
                    animation: blink-effect 2s infinite;

                    .topview_title_end1wrap_wrap {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;

                        .topview_title_end1_wrap_first {
                            margin: 0 0 20px 0;
                        }

                        .topview_title_end1_wrap_second {
                            margin: 0 0 25px 4px;
                        }
                    }

                    @media (max-width: $mobile-size) {
                        top: 40%;
                        transform: translateX(-50%) translateY(-40%);
                        @include fontBold(28px, #1bcaff);
                        width: 100%;
                    }
                }
                .topview_title_end2_wrap {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    width: 100%;
                    transform: translateX(-50%);
                    @include fontBold(42px, #1bcaff);
                    -webkit-filter: blur(7px);
                    filter: blur(7px);
                    z-index: 1;
                    animation: blink-effect 2s infinite;

                    @media (max-width: $mobile-size) {
                        top: 40%;
                        transform: translateX(-50%) translateY(-40%);
                        @include fontBold(28px, #1bcaff);
                        width: 100%;
                    }
                }
            }

            .textbuttons {
                position: absolute;
                width: 340px;
                height: 40px;
                left: 50%;
                transform: translateX(-50%);
                bottom: 30px;
                display: flex;
                flex-direction: row;

                @media (max-width: $mobile-size) {
                    width: 310px;
                    height: 40px;
                }

                .prevbutton {
                    width: 160px;
                    height: 40px;
                    border-radius: 4px;
                    border: solid 1px rgba(255, 255, 255, 0.3);
                    background-color: rgba(216, 216, 216, 0.1);
                    margin: 0 20px 0 0;
                    // @include font(18px, #fff);
                    font-family: "Roboto", sans-serif;
                    font-size: 16px;
                    color: #fff;
                    line-height: 40px;
                    cursor: pointer;

                    @media (max-width: $mobile-size) {
                        width: 150px;
                        margin: 0 10px 0 0;
                    }

                    &:hover {
                        cursor: pointer;
                        border: solid 1px rgb(115, 244, 253);
                        // @include font(18px, rgb(115, 244, 253));
                        color: rgb(115, 244, 253);
                    }
                }

                .nextbutton {
                    width: 160px;
                    height: 40px;
                    border-radius: 4px;
                    border: solid 1px rgba(255, 255, 255, 0.3);
                    background-color: rgba(216, 216, 216, 0.1);
                    // @include font(18px, #fff);
                    font-family: "Roboto", sans-serif;
                    font-size: 16px;
                    color: #fff;
                    line-height: 40px;
                    cursor: pointer;
                    @media (max-width: $mobile-size) {
                        width: 150px;
                        border: solid 1px rgba(255, 255, 255, 0.3);
                    }

                    &:hover {
                        cursor: pointer;
                        border: solid 1px rgb(115, 244, 253);
                        color: rgb(115, 244, 253);
                    }
                }
            }

            .textcontent1 {
                position: absolute;
                width: 680px;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                opacity: 0;
                transition-duration: 0.5s;
                -ms-user-select: none;
                -moz-user-select: -moz-none;
                -khtml-user-select: none;
                -webkit-user-select: none;
                user-select: none;

                @media (max-width: $mobile-size) {
                    width: 100%;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }
            }

            .textcontent2 {
                position: absolute;
                width: 680px;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                opacity: 0;
                transition-duration: 1s;
                -ms-user-select: none;
                -moz-user-select: -moz-none;
                -khtml-user-select: none;
                -webkit-user-select: none;
                user-select: none;

                @media (max-width: $mobile-size) {
                    width: 100%;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }
            }

            .textcontent3 {
                position: absolute;
                width: 680px;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                opacity: 0;
                // transition-delay: 1s;
                transition-duration: 1s;
                -ms-user-select: none;
                -moz-user-select: -moz-none;
                -khtml-user-select: none;
                -webkit-user-select: none;
                user-select: none;
                @media (max-width: $mobile-size) {
                    width: 100%;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }
            }

            .textcontent4 {
                position: absolute;
                width: 680px;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                opacity: 0;
                // transition-delay: 1s;
                transition-duration: 1s;
                -ms-user-select: none;
                -moz-user-select: -moz-none;
                -khtml-user-select: none;
                -webkit-user-select: none;
                user-select: none;

                @media (max-width: $mobile-size) {
                    width: 100%;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }
            }
        }

        .textbox_wrap {
            border-radius: 10px;
            -webkit-filter: blur(30px);
            filter: blur(30px);
            border-style: solid;
            border-width: 1px;
            border-image-source: linear-gradient(
                115deg,
                #3707cb,
                #84f924 48%,
                #ff00a1 100%
            );
            border-image-slice: 1;
            background-image: linear-gradient(
                    115deg,
                    #610dff,
                    #06e0cb 55%,
                    #ff00c6 100%
                ),
                linear-gradient(115deg, #3707cb, #84f924 48%, #ff00a1 100%);
            background-origin: border-box;
            background-clip: content-box, border-box;
            width: 760px;
            height: 280px;
            position: absolute;
            top: 350px;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            // transition-delay: 1s;
            // transition-duration: 0.5s;
            animation: blink-effect 5s infinite;
            z-index: 3;
            @media (max-width: $mobile-size) {
                width: calc(100% - 20px);
                height: 244px;
                left: 50%;
                top: 180px;
                transform: translateX(-50%);
            }
        }
    }

    .topview {
        height: 756px;
        background: transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;

        @media (max-width: $mobile-size) {
            height: 500px;
        }

        .topview_title_end {
            position: absolute;
            @include fontBold(50px, #fff);
            top: 280px;
            left: 50%;
            transform: translateX(-50%);
            white-space: pre-wrap;
            z-index: 2;
        }

        .topview_container {
            width: 1266px;
            height: 553px;
            margin: 0 auto;
            position: relative;

            @media (max-width: $mobile-size) {
                width: calc(100% - 20px);
                height: 80%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .mobilestatus {
                display: none;
                @media (max-width: $mobile-size) {
                    background-color: rgba(#fff, 0.1);

                    height: 24px;
                    width: calc(100% + 20px);
                    position: absolute;
                    bottom: 0;
                    left: -10px;

                    .state1_m {
                        position: relative;
                        opacity: 0;

                        .state_text_m {
                            position: absolute;
                            top: 3px;
                            left: 50%;
                            transform: translateX(-50%);
                            @include font(20px, #fff);
                        }

                        .state_text_wrap {
                            position: absolute;
                            top: 3px;
                            left: 50%;
                            transform: translateX(-50%);
                            -webkit-filter: blur(2px);
                            filter: blur(2px);
                            font-stretch: normal;
                            @include font(20px, #fff);
                        }
                    }

                    .state2_m {
                        position: relative;
                        opacity: 0;

                        .state_text_m {
                            position: absolute;
                            top: 3px;
                            left: 50%;
                            transform: translateX(-50%);
                            @include font(20px, #fff);
                        }

                        .state_text_wrap {
                            position: absolute;
                            top: 3px;
                            left: 50%;
                            transform: translateX(-50%);
                            -webkit-filter: blur(2px);
                            filter: blur(2px);
                            font-stretch: normal;
                            @include font(20px, #fff);
                        }
                    }

                    .state3_m {
                        position: relative;
                        opacity: 0;

                        .state_text_m {
                            position: absolute;
                            top: 3px;
                            left: 50%;
                            transform: translateX(-50%);
                            @include font(20px, #fff);
                        }

                        .state_text_wrap {
                            position: absolute;
                            top: 3px;
                            left: 50%;
                            transform: translateX(-50%);
                            -webkit-filter: blur(2px);
                            filter: blur(2px);
                            font-stretch: normal;
                            @include font(20px, #fff);
                        }
                    }

                    .state4_m {
                        position: relative;
                        opacity: 0;

                        .state_text_m {
                            position: absolute;
                            top: 3px;
                            left: 50%;
                            transform: translateX(-50%);
                            @include font(20px, #fff);
                        }

                        .state_text_wrap {
                            position: absolute;
                            top: 3px;
                            left: 50%;
                            transform: translateX(-50%);
                            -webkit-filter: blur(2px);
                            filter: blur(2px);
                            font-stretch: normal;
                            @include font(20px, #fff);
                        }
                    }
                }
            }

            .imgs {
                width: 100%;
                height: 553px;
                transition-duration: 2s;

                @media (max-width: $mobile-size) {
                    display: none;
                }
            }
        }
    }
}

@keyframes moveUpDown {
    0% {
        margin-top: 0px;
    }
    50% {
        margin-top: 10px;
    }
    to {
        margin-top: 0px;
    }
}

@keyframes moveDownUp {
    0% {
        margin-bottom: 0px;
    }
    50% {
        margin-bottom: 10px;
    }
    to {
        margin-bottom: 0px;
    }
}

@keyframes blink-effect {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes blink-effect2 {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    75% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
