@import "mixins";
@import "variables";

.main {
    position: relative;
    width: 100vw;
    display: flex;
    flex-direction: column;

    .contents {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        overflow: hidden;

        .main_nav {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 4;
            background: #000;

            @media (max-width: $mobile-size) {
                display: none;
            }
        }

        .main_nav_mobile {
            display: none;

            @media (max-width: $mobile-size) {
                display: flex;
                position: fixed;
                top: 0;
                left: 0;
                z-index: 4;
                width: 100%;
                height: 60px;
            }
        }
    }
}

body {
    margin: 0px;
    width: 100vw;
    background-color: #000;
}

.main_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f7ece1;

    .main_nav {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 4;
        background: #000;

        @media (max-width: $mobile-size) {
            display: none;
        }
    }

    .main_nav_mobile {
        display: none;

        @media (max-width: $mobile-size) {
            display: flex;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 4;
            width: 100%;
            height: 60px;
        }
    }

    .main_card_dot {
        position: absolute;
        left: calc(50% - 40px);
        top: calc(50% - 40px);
        transition-duration: 0.5s;
        width: 80px;
        height: 80px;

        border-radius: 40px;
    }
}

.main_card_wrapper {
    top: 0px;
    width: 100vw;
    background-color: black;
    overflow: hidden;
}

.main_card {
    position: relative;

    width: 100vw;
    display: flex;
    flex-direction: column;

    transition-duration: 0.5s;
}

@media (max-width: 1000px) {
    body {
        margin: 0px;
        width: 100vw;
        background-color: #000;
    }

    .main_container {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    #main_card_wrapper {
        left: 0%;
        transform: translateX(0%);
        width: 100%;
        height: 100%;
    }

    .main_card {
        width: 100%;
        height: 100%;
    }

    #main_delay_popup_buttons {
        background-color: white;
        width: 100%;
        height: 39px;

        display: flex;
        flex-direction: row;
        align-items: center;
    }

    #main_delay_popup_button1 {
        cursor: pointer;
        margin-left: 19px;
        height: 100%;

        display: flex;
        align-items: center;

        font-family: "APPLE";
        font-size: 14px;
        text-align: center;
        color: #a0a0a0;

        text-decoration: underline;
    }

    #main_delay_popup_button2 {
        cursor: pointer;
        margin-right: 19px;
        height: 100%;

        display: flex;
        align-items: center;

        font-family: "APPLE";
        font-size: 14px;
        text-align: center;
        font-weight: bold;
        color: #ffa500;
    }

    #main_card_pager_wrapper {
        position: absolute;
        right: 7px;
        top: 50%;
        transform: translateY(-50%);
    }

    #main_card_scrollguide {
        display: none;
    }

    .main_card_dot {
        left: calc(50% - 40px);
        top: calc(50% - 40px);

        width: 80px;
        height: 80px;

        border-radius: 40px;
    }

    .main_card_dot_text_wrapper {
        left: calc(50% - 40px);
        top: calc(50% - 40px);

        width: 80px;
        height: 80px;

        border-radius: 40px;
    }

    .main_card_dot_text {
        font-size: 14px;
        height: 34px;
    }

    .main_card_dot_hand_wrapper {
        left: calc(50% - 40px);
        top: calc(50% - 40px);
        width: 80px;
        height: 80px;
    }

    .main_card_dot_hand {
        width: 17px;
        height: 27px;
    }
}
