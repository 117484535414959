@import "mixins";
@import "variables";

.navbar {
    height: 60px;
    width: 100vw;
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    .navbar_wrap {
        height: 60px;
        width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @media (max-width: $width-pc1) {
            width: calc(100% - 40px);
        }

        .logo {
            width: 154px;
            @include font(24px, #fff);
            display: flex;
            flex-direction: column;
            justify-content: center;
            cursor: pointer;

            .logoimg {
                background-size: cover;
                background-image: url("../../../Assets/Images/logo-tp.png");
                height: 26px;
                width: 154px;
            }
        }

        .sideWrapper {
            display: flex;
            align-items: center;

            .menus {
                display: flex;
                flex-direction: row;
                align-items: center;

                .terrapoker {
                    margin: 0 30px 0 0;
                    @include fontLight(16px, rgba(255, 255, 255, 0.3));
                    cursor: pointer;

                    &.enable {
                        color: #fff;

                        &:hover {
                            color: #fff;
                        }
                    }

                    &:hover {
                        color: #fff;
                    }
                }

                .introduction {
                    margin: 0 30px 0 0;
                    @include fontLight(16px, rgba(255, 255, 255, 0.3));
                    cursor: pointer;

                    &.enable {
                        color: #fff;

                        &:hover {
                            color: #fff;
                        }
                    }

                    &:hover {
                        color: #fff;
                    }
                }

                .roadmap {
                    margin: 0 30px 0 0;
                    @include fontLight(16px, rgba(255, 255, 255, 0.3));
                    cursor: pointer;

                    &.enable {
                        color: #fff;

                        &:hover {
                            color: #fff;
                        }
                    }

                    &:hover {
                        color: #fff;
                    }
                }

                .economy {
                    @include fontLight(16px, rgba(255, 255, 255, 0.3));
                    cursor: pointer;

                    &.enable {
                        color: #fff;

                        &:hover {
                            color: #fff;
                        }
                    }

                    &:hover {
                        color: #fff;
                    }
                }
            }

            .webappButton {
                width: 100px;
                padding: 7px 0;
                border-radius: 5px;
                border: solid 1px rgba(#fff, 0.3);
                margin-left: 30px;
                font-size: 14px;
                color: #1bffbc;
                cursor: pointer;
                text-decoration: none;

                &:hover {
                    border: solid 1px rgba(255, 255, 255, 0.2);
                    background-color: #fff;
                    color: #0f3840;
                }

                &:active {
                    border: solid 1px rgba(255, 255, 255, 0.2);
                    background-color: rgba(255, 255, 255, 0.3);
                    color: #1bffbc;
                }
            }

            .webappButtonDimmed {
                width: 100px;
                padding: 7px 0;
                border-radius: 5px;
                border: solid 1px rgba(#fff, 0.3);
                margin-left: 30px;
                font-size: 14px;
                color: rgba(#fff, 0.3);
                background-color: rgba(216, 216, 216, 0.1);
                cursor: not-allowed;
            }

            .verticalLine {
                width: 1px;
                height: 14px;
                background: rgba(255, 255, 255, 0.3);
                margin: 0 0 0 30px;
            }

            .litePaperWrapper {
                display: flex;
                flex-direction: row;
                align-items: center;

                &:hover {
                    cursor: pointer;

                    .litePaperIcon {
                        background-image: url("../../../Assets/Images/icon-lite-paper-on.svg");
                    }

                    .litePaper {
                        color: #fff;
                    }
                }

                .litePaperIcon {
                    background-size: cover;
                    background-image: url("../../../Assets/Images/icon-lite-paper.svg");
                    height: 40px;
                    width: 40px;
                    margin: 0 0 0 20px;
                }

                .litePaper {
                    @include fontLight(14px, rgba(255, 255, 255, 0.3));
                    text-decoration: none;
                    margin: 0 0 0 4px;
                }
            }
        }
    }
}
